import React from "react"
import { Link } from "gatsby"
import "../styles/global.css"
import "../styles/index.css"

function IndexPage() {
  return (
    <main>
      <div className="parent">
        <nav>
          <p className="brightly__stories">
            <Link to="/stories">Stories</Link>
          </p>
          <p className="brightly__instagram">
            <a href="https://www.instagram.com/brightlyfilms/">Instagram</a>
          </p>
        </nav>
        <header>
          <h1 className="brightly__heading">Brightly</h1>
        </header>
      </div>
    </main>
  )
}

export default IndexPage
